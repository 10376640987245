<template>
  <PopupView
    ref="popupRef"
    :width="90"
    :is_close="false"
    :maskFun="maskFun"
    @popupShow="popupShow"
    @popupClose="popupClose"
  >
    <div class="modal-container">
      <div v-if="title" class="title tc fw500 fs16">{{ title }}</div>
      <slot></slot>
      <div class="handle-container flex flex-align-center">
        <div
          v-if="showCancelButton"
          class="handle-cancel-btn flex-1 cl69 fs16 fw400 flex flex-justify-center flex-align-center"
          :style="{
            'border-right': showConfirmButton ? '1px solid #E5E6EB' : 'none',
            color: cancelTextColor
          }"
          @click="onCancel"
        >
          {{ cancelText }}
        </div>
        <div
          v-if="showConfirmButton && (!asyncClose || !closeLoading)"
          class="handle-confirm-btn flex-1 fs16 fw500 flex flex-justify-center flex-align-center"
          :style="{ color: confirmTextColor }"
          @click="onConfirm"
        >
          {{ confirmText }}
        </div>
        <div
          v-if="showConfirmButton && asyncClose && closeLoading"
          class="handle-confirm-btn flex-1 fs16 fw500 flex flex-justify-center flex-align-center"
          :style="{ color: confirmTextColor }"
        >
          <LoadingIcon :backColor="confirmTextColor" />
        </div>
      </div>
    </div>
  </PopupView>
</template>

<script>
import PopupView from '../popupView/index.vue'
import LoadingIcon from '../loadingIcon/index.vue'

export default {
  components: { PopupView, LoadingIcon },
  props: {
    // 双向绑定visible
    modelValue: {
      type: Boolean,
      default: () => false
    },
    // 是否点击遮罩关闭弹窗
    maskFun: {
      type: Boolean,
      default: false
    },
    // 弹窗标题
    title: {
      type: String,
      default: ''
    },
    // 显示取消按钮
    showCancelButton: {
      type: Boolean,
      default: true
    },
    // 显示确认按钮
    showConfirmButton: {
      type: Boolean,
      default: true
    },
    // 确认按钮文字
    confirmText: {
      type: String,
      default: '确认'
    },
    // 取消按钮文字
    cancelText: {
      type: String,
      default: '取消'
    },
    // 确认按钮color
    confirmTextColor: {
      type: String,
      default: '#029d9d'
    },
    // 取消按钮color
    cancelTextColor: {
      type: String,
      default: '#4E5969'
    },
    // 是否异步关闭
    // 开启异步关闭,Modal不会自动关闭，需要手动设置通过v-model绑定的变量为false来实现手动关闭
    asyncClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      closeLoading: false // 关闭加载状态
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  watch: {
    modelValue(newval, oldval) {
      console.log('9999999', newval, this.$refs)
      if (!this.$refs.popupRef || newval === oldval) return false
      if (newval) {
        this.$refs.popupRef.show()
      } else {
        this.$refs.popupRef.close()
      }
    }
  },
  methods: {
    // 显示弹窗
    popupShow() {
      this.$emit('update:modelValue', true)
    },
    // 隐藏弹窗
    popupClose() {
      if (this.closeLoading) this.closeLoading = false
      this.$emit('update:modelValue', false)
    },
    // 确认事件
    async onConfirm() {
      const { asyncClose } = this
      if (asyncClose) {
        this.closeLoading = true
        this.$emit('confirm')
      } else {
        this.$emit('confirm')
        this.popupClose()
      }
    },
    // 取消事件
    onCancel() {
      this.$emit('cancel')
      this.popupClose()
    }
  }
}
</script>

<style lang="less" scoped>
.modal-container {
  .title {
    margin-top: 18px;
    margin-bottom: 16px;
  }

  .handle-container {
    border-top: 1px solid #e5e6eb;
  }

  .handle-cancel-btn,
  .handle-confirm-btn {
    height: 52px;
  }
}
</style>
