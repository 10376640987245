<template>
  <div class="container fs14">
    <div
      v-if="params.pageType === 'task' && params.end_time"
      class="fs12 cl9c flex flex-align-center mt12 mb3 plr15"
    >
      <span class="mr4">距离任务结束剩余</span>
      <span v-if="countDownData.day" class="clth mr4">
        {{ countDownData.day }}
      </span>
      <span v-if="countDownData.day">天</span>
      <span v-if="countDownData.hour" class="clth ml4 mr4">
        {{ countDownData.hour }}
      </span>
      <span v-if="countDownData.hour">时</span>
      <span v-if="countDownData.minute" class="clth ml4 mr4">
        {{ countDownData.minute }}
      </span>
      <span v-if="countDownData.minute">分</span>
      <span v-if="countDownData.second" class="clth ml4 mr4">
        {{ countDownData.second }}
      </span>
      <span v-if="countDownData.second">秒</span>
    </div>
    <div class="page-content bdr5 plr15 pt9">
      <div v-if="params.title" class="pb9 cl29 fw500">
        {{ params.title }}
      </div>
      <div
        v-if="isEditable"
        class="content-input-box bgf pt15 pb30 pl12 pr12 relative"
      >
        <textarea
          v-model="writing"
          class="content-input"
          :show-confirm-bar="false"
          placeholder="在此输入内容"
          confirm-type="done"
          :maxlength="800"
        />
        <div class="limit-text">
          <div class="fs13 cl99">
            {{ (writing && writing.length) || 0 }}/800
          </div>
        </div>
      </div>
      <div
        v-else
        class="writing-read-only bgf pt15 pb30 pl12 pr12 relative wb ws"
      >
        {{ writing }}
      </div>
      <div
        v-if="mediaList.length || isEditable"
        class="content-media bgf pt12 plr12 bdt"
      >
        <MediaGride
          :isEdit="isEditable"
          :mediaList="mediaList"
          @updateMediaList="updateMediaList"
        />
      </div>
    </div>
    <div class="btn-box flex pl15 pr15 mt22">
      <button
        v-if="isEditable"
        class="handle-def-btn flex-1 mr15"
        @click="modalVisible = true"
      >
        重置
      </button>
      <button class="handle-btn flex-1" @click="showPopup">发送</button>
    </div>
    <div
      v-if="params.pageType === 'task' && isEditable"
      class="flex flex-justify-center flex-align-center pt15 clth fs12 mt50 pbs15"
      @click="hintModalVisible = true"
    >
      <div class="not-should-send relative">本次不该发送给 TA</div>
    </div>
    <!-- <uni-popup 
			ref="shareCover" 
			type="share" 
			safe-area 
			background-color="#fff"
			style="position: fixed; z-index: 99;"
		>
			<div class="share-pop-sendItem pl70 pr70 pt50" @click.stop="">
				<div class="flex flex-justify-between pb50">
					<div class="share-item" @click="onHandle('customer')">
						<image class="share-item-icon block" :src="`${$CDNURL}knowledge/customer.svg`" mode="widthFix">
						</image>
						<div class="fs24 lh34 cl17 pt10">
							群发至客户
						</div>
					</div>
					<div class="share-item" @click="onHandle('group')">
						<image class="share-item-icon block" :src="`${$CDNURL}knowledge/customer_group.svg`"
							mode="widthFix"></image>
						<div class="fs24 lh34 cl17 pt10">
							群发至客户群
						</div>
					</div>
					<div class="share-item" @click="onHandle('circleFriends')">
						<image class="share-item-icon block" :src="`${$CDNURL}knowledge/Moments.svg`" mode="widthFix">
						</image>
						<div class="fs24 lh34 cl17 pt10">
							发至朋友圈
						</div>
					</div>
				</div>
				<div class="cancel-btn cl17 tc fs28" @click="closePopup">
					取消
				</div>
			</div>
		</uni-popup> -->
    <ModalView
      v-model="modalVisible"
      async-close
      @confirm="getDataSource"
      @cancel="modalVisible = false"
    >
      <div class="cl32 cl29 fw500 pt30 pb50 tc">确认重置内容?</div>
    </ModalView>
    <ModalView
      v-model="groupSendModal"
      async-close
      :confirmText="'创建群发'"
      @confirm="createGroupSend"
      @cancel="groupSendModal = false"
    >
      <div class="flex flex-justify-center flex-align-center p32">
        <span class="fw500 cl29 lh20 fs16">
          创建群发后，在企业微信中找到 “消息”-“群发助手”- 点击“发送”
        </span>
      </div>
    </ModalView>
    <ModalView v-model="hintModalVisible" maskFun @confirm="onNotShouldSend">
      <div class="cl29 fw500 ptb32 tc">本次不该发送给客户?</div>
    </ModalView>
  </div>
</template>

<script>
import MediaGride from '@/components/detail/mediaGride.vue'
// import { navigateTo, navigateBack } from '@/utils/router.js'
// import { getRouteParams } from '@/utils/router.js'
import ModalView from '@/components/modalView/index.vue'
import {
  getSaleScriptByMember,
  setSaleScriptByMember,
  addSendContent
} from '@/service/material.service.js'
// import { getItem } from '@/utils/index.js'
import { getBulkMsgSend } from '@/service/customer.service.js'
import {
  getContext,
  sendChatMessageAllType,
  getCurExternalContact,
  getCurExternalChat
} from '@/utils/nwechat.js'
import {
  formatType,
  formatTaskItem,
  formatSendParams
} from '@/utils/sendMessage.js'
import {
  sendMonitor,
  setBackSend,
  recordEditTask,
  backTaskStatus
} from '@/service/quickSend.service.js'
import { getUploadByMedia } from '@/service/upload.service'
import { Toast } from 'vant'
import { decodeParams } from '@/utils/router'
import { taskRefused } from '@/service/task.service.js'

export default {
  name: 'content-edit',
  components: { ModalView, MediaGride },
  data() {
    return {
      params: {
        // 路由参数
        // pageType:  页面类型 'task': 任务, 'strategy': 策略, 'groupSend': 客户列表群发
      },
      modalVisible: false, // 弹窗显示
      hintModalVisible: false, // 不该发送提示弹窗
      isEditable: true, // 是否可编辑
      mediaList: [], // 附件列表
      writing: '', // 文案
      qw_external_userid: '', // 外部联系人userid (聊天工具栏才有)
      qw_chat_id: '', // 当前客户群的群ID (聊天工具栏才有)
      groupSendModal: false, // 创建群发弹窗
      countDownData: {
        // 倒计时数据
        day: '0',
        hour: '00',
        minute: '00',
        second: '00'
      },
      taskTimes: null // 任务倒计时定时器
    }
  },
  async mounted() {
    this.params = decodeParams(this.$route.query)
    const { isEdit, pageType, end_time } = this.params
    this.isEditable = Boolean(isEdit)
    document.title =
      pageType === 'task'
        ? this.isEditable
          ? '任务执行'
          : '任务查看'
        : '内容编辑'
    // uni.setNavigationBarTitle({
    // 	title: pageType === 'task'
    // 		? this.isEditable
    // 			? '任务执行'
    // 			: '任务查看'
    // 		: '内容编辑'
    // })
    if (this.taskTimes) clearInterval(this.taskTimes)
    if (pageType === 'task' && end_time) {
      this.taskTimes = setInterval(() => this.countDown(), 1000)
    }
    // uni.showLoading({ title: '加载中',	mask: true })
    Toast.loading({
      message: '加载中',
      duration: 0,
      forbidClick: true
    })
    await this.getEntryType()
    await this.getDataSource()
    Toast.clear()
  },
  methods: {
    // 显示群发类型弹窗
    showPopup() {
      const {
        mediaList,
        writing,
        qw_external_userid,
        qw_chat_id,
        params
      } = this
      const { pageType } = params
      if (!mediaList.length && !writing) return Toast('发送内容不可为空')
      // uni.showToast({
      // 	title: '发送内容不可为空',
      // 	icon: 'none'
      // })
      if (pageType === 'groupSend') {
        this.groupSendModal = true
      } else {
        if (qw_external_userid || qw_chat_id) {
          this.onSend()
        } else {
          this.$refs.shareCover.open()
        }
      }
    },
    // 关闭群发类型弹窗
    closePopup() {
      this.$refs.shareCover.close()
    },
    // 文案输入框change
    // changeStatus(e) {
    //   this.writing = e.detail.value ? e.detail.value.substring(0, 800) : ''
    // },
    // 选择群发类型 (该逻辑还没完善, 需要使用临时标签逻辑请补充)
    async onHandle(key) {
      // const { isEditable, params } = this
      // const { sale_script_id, content_key } = params
      // const { qw_userid } = getItem('user_info')
      // setTimeout(() => this.closePopup(), 300)
      // const data = await this.setDataSource()
      // const content_member_id = isEditable ? qw_userid : '0'
      // if (key === 'group') {
      // 	navigateTo('/pagesTool/sendGuide/index', {
      // 		pageType: key,
      // 		materialList: data
      // 	})
      // } else {
      // 	navigateTo('/pagesTool/screenCustomer/index', {
      // 		pageType: key,
      // 		sale_script_id,
      // 		content_key,
      // 		content_member_id,
      // 		materialList: data
      // 	})
      // }
    },
    // 发送内容
    // async setDataSource() {
    // 	try {
    // 		const { params, writing, mediaList, isEditable } = this
    // 		const { sale_script_id, content_key } = params
    // 		const body = {
    // 			sale_script_id,
    // 			content_key,
    // 			content_arr: []
    // 		}

    // 		if (mediaList.length) body.content_arr = [ ...mediaList ]
    // 		if (writing) {
    // 			body.content_arr.unshift({
    // 				type: "TEXT",
    // 				content: writing,
    // 				content_key
    // 			})
    // 		}
    // 		body.content_arr = body.content_arr.map((item, index) => ({
    // 			...item,
    // 			sort: body.content_arr.length - index
    // 		}))

    // 		if (isEditable) {
    // 			await setSaleScriptByMember(body)
    // 		}

    // 		return Promise.resolve(body.content_arr)
    // 	} catch (error) {
    // 		return Promise.reject(error)
    // 		console.log(error)
    // 	}
    // },
    // 获取内容数据
    async getDataSource() {
      const { params } = this
      const { pageType, annex_content_json = '[]', content = '' } = params

      const typeArray = []
      if (typeArray.includes(pageType)) {
        this.mediaList = [...annex_content_json]
      } else {
        this.mediaList = [...formatTaskItem(annex_content_json)]
      }
      this.writing = content.substring(0, 800)
      console.log('文案', this.writing)
      console.log('附件', this.mediaList)
      if (this.modalVisible) this.modalVisible = false
    },
    // 更新附件
    updateMediaList(data = []) {
      this.mediaList = data
    },
    // 获取页面入口类型
    async getEntryType() {
      // this.qw_external_userid = 'wm1DICCQAAq9tj0yzhQu1uDVYbKoTnLg'
      const entry = await getContext()
      if (entry === 'single_chat_tools') {
        this.qw_external_userid = await getCurExternalContact()
      } else if (entry === 'group_chat_tools') {
        this.qw_chat_id = await getCurExternalChat()
      }
    },
    // 聊天工具栏发送内容包
    async onSend() {
      try {
        const {
          qw_external_userid,
          qw_chat_id,
          mediaList,
          writing,
          params
        } = this

        const { pageType, task_id, strategy_id } = params
        let share_data_id = ''
        if (pageType === 'task' || pageType === 'strategy') {
          share_data_id = await this.beforeSend()
        }
        let status = false
        let contentArr = []
        if (mediaList.length) contentArr = [...mediaList]
        if (writing) {
          contentArr.unshift({ type: 'TEXT', content: writing })
        }
        Toast.loading({
          message: '加载中',
          duration: 0,
          forbidClick: true
        })
        for (let i = 0; i < contentArr.length; i++) {
          let type = formatType(contentArr[i].type)
          let params = await formatSendParams(
            type,
            {
              ...contentArr[i],
              customer_id: qw_external_userid,
              chart_id: qw_chat_id,
              task_id: task_id || '',
              strategy_id: strategy_id || '',
              source: pageType
            },
            share_data_id
          )
          console.log('发送参数:::', type, params)
          const { sendStatus } = await sendChatMessageAllType(
            type,
            params,
            false
          )
          if (sendStatus) status = true
        }

        Toast.clear()
        if (status) {
          if (pageType === 'task') {
            await this.recordEditTask()
            await this.sendComplete()
          } else if (pageType === 'strategy') {
            this.recordDynamics()
            this.sendSucceed()
          }
        } else {
          Toast.clear()
        }
      } catch (error) {
        Toast.clear()
        console.log(error)
      }
    },
    // 发送成功
    sendSucceed() {
      Toast.success('发送成功!')
      setTimeout(() => {
        this.$router.back()
      }, 1500)
    },
    // 发送前获取埋点id
    async beforeSend() {
      const { params, qw_external_userid } = this
      const { pageType, task_id, strategy_id } = params

      if (!qw_external_userid) return ''
      const body = {
        payable_id: '',
        type: pageType,
        user_id: qw_external_userid
      }
      if (pageType === 'task') body.payable_id = task_id
      if (pageType === 'strategy') body.payable_id = strategy_id

      let { data } = await sendMonitor(body)
      return data?.share_data_id || ''
    },
    // 记录发送动态
    async recordDynamics() {
      const { params, qw_external_userid, qw_chat_id } = this
      const { strategy_id } = params

      const body = {
        strategy_id,
        field_value: qw_external_userid || qw_chat_id || '',
        event_key: '',
        field: ''
      }
      if (qw_external_userid) {
        body.event_key = 'customer_receive_strategy'
        body.field = 'external_contact'
      } else if (qw_chat_id) {
        body.event_key = 'customer_touch_strategy_group'
        body.field = 'chat_id'
      }

      await setBackSend(body)
    },
    // 记录编辑过的任务
    async recordEditTask() {
      const {
        params,
        isEditable,
        mediaList,
        writing,
        qw_external_userid,
        qw_chat_id
      } = this
      const { content, task_id } = params
      if (writing === content?.slice(0, 800) && !isEditable) {
        return false
      }
      const body = {
        task_id,
        content: writing || '',
        annex_content_json: mediaList || [],
        external_userid: qw_external_userid || qw_chat_id
      }
      await recordEditTask(body)
    },
    async sendComplete() {
      // 回传改变状态-任务
      const { qw_external_userid, qw_chat_id, params } = this
      const { task_send_record_son_id } = params
      const userInfo = JSON.parse(localStorage.getItem('vuex')) || {}
      const { code } = await backTaskStatus({
        send_record_son_id: task_send_record_son_id,
        member_userid: userInfo.user.info.qw_userid,
        external_userid: qw_external_userid || qw_chat_id,
        send_status: 'success'
      })

      if (code == 200) {
        this.sendSucceed()
      }
    },
    // 不应该发送
    async onNotShouldSend() {
      const { qw_external_userid, qw_chat_id, params } = this
      const { task_id } = params
      const { code } = await taskRefused({
        task_id,
        type: '2',
        userid: qw_external_userid || qw_chat_id
      })

      if (code == 200) {
        Toast.success('操作成功!')
        setTimeout(() => {
          this.$router.back()
        }, 2000)
      }
    },
    // 创建群发
    async createGroupSend() {
      const { params, writing, mediaList } = this
      const { messageParams } = params
      const typeEnum = {
        IMAGE: 'images',
        LINK: 'web_page',
        MINIPROGRAM: 'we_applets',
        VIDEO: 'video',
        FILE: 'FILE'
      }
      const annex_content_json = mediaList.map((item) => ({
        ...item,
        type: typeEnum[item.type]
      }))
      const body = {
        ...messageParams,
        send_type: '3',
        content: writing,
        annex_content_json
      }

      const { code } = await getBulkMsgSend(body)
      if (code == 200) {
        Toast.success('创建成功!')
        this.groupSendModal = false
        setTimeout(() => this.$router.back(), 1500)
      }
    },
    // 计算倒计时时间
    countDown() {
      const { end_time } = this.params
      if (!end_time) return false
      const endTime = end_time.replace(/-/g, '/')
      var nowTime = Date.now() //当前时间
      var setDate = new Date(endTime)
      var setTime = setDate.getTime() //设定的时间
      if (nowTime >= setTime) {
        if (this.taskTimes) clearInterval(this.taskTimes)
        return (this.countDownData = {
          day: '0',
          hour: '00',
          minute: '00',
          second: '00'
        })
      }
      //获取剩余时间总秒数
      var getTimes = (setTime - nowTime) / 1000
      //获取天数
      var d = parseInt(getTimes / 60 / 60 / 24)
      d = d
      //获取小时
      var h = parseInt((getTimes / 60 / 60) % 24)
      h = h < 10 ? '0' + h : h
      //获取分钟
      var m = parseInt((getTimes / 60) % 60)
      m = m < 10 ? '0' + m : m
      //获取秒数
      var s = parseInt(getTimes % 60)
      s = s < 10 ? '0' + s : s
      this.countDownData = {
        day: Number(d) ? d : '',
        hour: Number(d) || Number(h) ? h : '',
        minute: Number(h) || Number(m) ? m : '',
        second: !d && h <= 1 ? s : ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.mb3 {
  margin-bottom: 3px;
}
.ml4 {
  margin-left: 4px;
}
.mr4 {
  margin-right: 4px;
}
.mt12 {
  margin-top: 12px;
}
.pt9 {
  padding-top: 9px;
}
.pb9 {
  padding-bottom: 9px;
}
.pl12 {
  padding-left: 12px;
}
.pr12 {
  padding-right: 12px;
}
.mt22 {
  margin-top: 22px;
}
.ptb32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.pbs15 {
  padding-bottom: calc(15px + env(safe-area-inset-bottom) / 2);
}
.lh40 {
  line-height: 40px;
}
.right-allow {
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
}
.container {
  padding-bottom: calc(15px + env(safe-area-inset-bottom) / 2);
}
.hint-update {
  height: 40px;
  background: rgba(2, 157, 157, 0.1);
}
.page-content {
  overflow: hidden;

  .content-input-box {
    border-radius: 5px 5px 0 0;
  }
  .content-input {
    width: 100%;
    height: 100px;
    border: none;
    resize: none;
  }

  .writing-read-only {
    min-height: 150px;
    border-radius: 5px 5px 0 0;
  }
  .content-media {
    border-radius: 0 0 5px 5px;
  }
  .limit-text {
    position: absolute;
    bottom: 9px;
    right: 12px;
    color: #999;
    font-size: 13px;
  }
}

.share-pop-sendItem {
  border-radius: 10px;

  .share-item-icon {
    width: 42px;
    height: 42px;
    margin: 0 auto;
  }

  .cancel-btn {
    line-height: 40px;
  }
}
.not-should-send {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: @theme-color;
  }
}
</style>
